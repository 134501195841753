import Recaptcha from 'react-google-invisible-recaptcha';
import React from 'react';
import LazyLoad from 'react-lazyload';

class ContactForm extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            name: "",
            email: "",
            message:"",
            success: "",
            error: "",
            submitting: false,
            formValid: false,
            emailValid: null,
            nameValid: null,
            messageValid: null,
            showEmailError: false,
            showNameError: false,
            showMessageError: false,
            emailClass: "normal-input",
            nameClass: "normal-input",
            messageClass: "normal-input"
        };
        console.log(this.state.success);
        this.onSubmit = this.onSubmit.bind( this );
        this.onResolved = this.onResolved.bind( this );
    }
    onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name]: value },
                      () => { this.validateField(name,
                                                 value); });
    }
    validateField(fieldName, value) {
        let emailValid = this.state.emailValid;
        let nameValid = this.state.nameValid;
        let messageValid = this.state.messageValid;
        let emailClass, nameClass, messageClass;
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        switch(fieldName) {
        case 'email':
            emailValid = re.test(value);
            emailClass = emailValid ? "is-valid" : "has-error";
            this.setState({showEmailError: !emailValid});
            break;
        case 'name':
            nameValid = value.length >= 1;
            nameClass = nameValid ? "is-valid" : "has-error";
            this.setState({showNameError: !nameValid});
            break;
        case 'message':
            messageValid = value.length >= 1;
            messageClass = messageValid ? "is-valid" : "has-error";
            this.setState({showMessageError: !messageValid});
        default:
            break;
        }
        this.setState({emailValid: emailValid,
                       nameValid: nameValid,
                       messageValid: messageValid
                      });
        this.setState({
            emailClass: emailClass,
            nameClass: nameClass,
            messageClass: messageClass
        });
        this.setState({formValid: this.state.emailValid &&
                       this.state.nameValid &&
                       this.state.messageValid});
    }

    render() {
        return (
            <div>
              <form
                 noValidate
                 action={""}
                 method="post"
                 className={this.state.success === "" ? "cform-form" :  "hidden-form"}
                 id="ajax-contact-form"
                 >
                <div className="row">
                  <div className="span6">
                    {' '}
                    <span className="your-name">
                      <input
                         type="text"
                         className={this.state.showNameError ? "has-error" : "cform-text"}
                         name="name"
                         autoComplete="name"
                         placeholder="Your Name"
                         size="40"
                         title="your name"
                         onChange={this.onChange}
                         required
                         />
                      <div className="invalid-message"
                           style={this.state.showNameError ? {} : {display: "none"}}>
                        Please provide a name.
                      </div>
                    </span>{' '}
                  </div>
                  <div className="span6">
                    {' '}
                    <span className="email">
                      <input
                         type="text"
                         name="email"
                         className={this.state.showEmailError ? "has-error" : "cform-text"}
                         autoComplete="email"
                         placeholder="Your Email"
                         size="40"
                         title="your email"
                         pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                         onChange={this.onChange}
                         required
                         />
                      <div className="invalid-message"
                           style={this.state.showEmailError ? {} : {display: "none"}}>
                        Please provide a valid email address.
                      </div>
                    </span>{' '}
                  </div>
                </div>
                <div className="row">
                  <div className="span12">
                    {' '}
                    <span className="message">
                      <textarea
                         name="message"
                         className={this.state.showMessageError ? "has-error" : "cform-textarea"}
                         placeholder="Your Message"
                         cols="40"
                         rows="10"
                         title="drop us a line."
                         onChange={this.onChange}
                         required
                         />
                      <div className="invalid-message"
                           style={this.state.showMessageError ? {} : {display: "none"}}>
                        Please provide a message.
                      </div>
                    </span>{' '}
                  </div>
                </div>

                <div className="row">
                  <div className="span3">
                    <button
                       type="button"
                       className="message-submit"
                       onClick={this.onSubmit}
                       >Send Message
                    </button>
                  </div>
                  <div className="span6">
                    <div id="contact-loading" className={this.state.submitting ? "loading" : "contact-normal"}/>
                  </div>
                </div>


                <div style={{height: "60px", width: "256px", float:"right", paddingBottom: "2em"}}>
                 <LazyLoad offset={100}>
                  <Recaptcha
                     ref={ ref => this.recaptcha = ref }
                    sitekey="6LfrgWoUAAAAAJ0a6dmVir2yLCYY27tvqcMkDaah"
                    onResolved={ this.onResolved }
                    badge="inline"/>
                 </LazyLoad>
                </div>
              </form>
              <div className={this.state.success === "" ? "hidden-status" : (this.state.success === "true" ? "success" : "error")}>
                <span>{this.state.success === "true" ? "Your message has been sent. Thank you!" : "There was an error sending the message. Please reload the page and try again. (" + this.state.error + ")"}</span>
              </div>
            </div>
        );
    }
    onSubmit() {
        if (this.state.emailValid &&
            this.state.nameValid &&
            this.state.messageValid) {
            this.setState({submitting: true});
            this.recaptcha.execute();
        }
        else {
            this.state.emailValid ? this.setState({showEmailError: false}) : this.setState({showEmailError: true});
            this.state.nameValid ? this.setState({showNameError: false}) : this.setState({showNameError: true});
            this.state.messageValid ? this.setState({showMessageError: false}) : this.setState({showMessageError: true});
        }
    }
    onResolved() {
        var { name, email, message } = this.state;
        const token = this.recaptcha.getResponse();
        name = encodeURIComponent(name);
        email = encodeURIComponent(email);
        message = encodeURIComponent(message);


        fetch('https://www.ilsevalle.com/.netlify/functions/contact', {
            method: 'POST',
            body: JSON.stringify({
            name: name,
            email: email,
            message: message,
            recaptcha: token,
            }),
            //"name=" + name +"&email=" + email + "&message=" + message +
            //    "&recaptcha=" + token,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then((resp) => resp.json())
            .then(data => this.setState({ success: data.message === "Message Sent" ? "true": "false", error: data.message }))
            .catch(error => {console.log(error);
                             this.setState({ success: "false", error: "Internal Error"});
                            });;
    }
}

export default ContactForm;
