import React from 'react'
import { graphql } from 'gatsby'
//import Link from 'gatsby-link';
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import get from 'lodash/get'
//import PageTransition from 'gatsby-plugin-page-transitions';

//import MenuSidebar from '../components/MenuSidebar';
import SEO from '../components/SEO'
import Mailto from 'react-protected-mailto'
import ContactForm from '../components/ContactForm'

import icon32 from '../assets/images/favicon-32x32.png'
import social_image from '../assets/images/social-image.png'

//import Recaptcha from 'react-google-invisible-recaptcha';
import Template from '../components/layout'

class Homepage extends React.Component {
  render() {
    return (
      <Template location={get(this, 'props.location')}>
        <Helmet
          title={get(this, 'props.data.site.siteMetadata.title')}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${icon32}` },
            { rel: 'dns-prefetch', href: 'https://www.google-analytics.com' },
            {
              rel: 'alternate',
              href: 'https://www.ilsevalle.com/index_es',
              hreflang: 'es',
            },
            {
              rel: 'alternate',
              href: 'https://www.ilsevalle.com/',
              hreflang: 'x-default',
            },
            {
              rel: 'preload',
              as: 'image',
              type: 'image/avif',
              imagesrcset:
                '/img/food-salad4.300.avif 300w, /img/food-salad4.300.avif 600w, /img/food-salad4.1200.avif 1200w, /img/food-salad4.1800.avif 1800w, /img/food-salad4.2127.avif 2127w',
              imagesizes: '(max-width: 1200px) 100vw, 1200px',
            },
            {
              rel: 'preload',
              href: '/static/fontello-804ebbbc572ec0d391346343be251588.woff',
              as: 'font',
              type: 'font/woff',
              crossorigin: 'anonymous',
            },
            {
              rel: 'preload',
              href: '/fonts/patua-one/patua-one-v7-latin-regular.subset.woff2',
              as: 'font',
              type: 'font/woff2',
              crossorigin: 'anonymous',
            },
          ]}
        >
          <meta name="author" content="Diego Valle-Jones" />
          <html lang="en" />
        </Helmet>
        <SEO
          socialTitle="Ilse Valle-Jones"
          seodesc="Ilse Valle-Jones is a nutritionist in Collegeville, Pennsylvania. She can be your guide in designing healthy eating plans to lose weight."
          socialImage={social_image}
          siteUrl={get(this, 'props.data.site.siteMetadata.siteUrl')}
          socialDescription="Well-Being and Healthy Lifestyles"
          twitter="@ilsevallejones"
        />
        <div id="headerwrap">
          <div
            className="vegas-overlay"
            style={{
              margin: '0px',
              padding: '0px',
              position: 'relative',
              left: '0px',
              top: '0px',
              width: '100%',
              height: '100%',
            }}
          >
            <picture>
              <source
                srcset="/img/food-salad4.300.avif 300w,
/img/food-salad4.300.avif 600w,
/img/food-salad4.1200.avif 1200w,
/img/food-salad4.1800.avif 1800w,
/img/food-salad4.2127.avif 2127w"
                sizes="(max-width: 1200px) 100vw, 1200px"
                type="image/avif"
              ></source>
              <source
                type="image/webp"
                srcset="/img/bc216407de4c65a30eb9d49ef77e2680/d08b3/food-salad4.webp 300w,
/img/bc216407de4c65a30eb9d49ef77e2680/10e4a/food-salad4.webp 600w,
/img/bc216407de4c65a30eb9d49ef77e2680/30c24/food-salad4.webp 1200w,
/img/bc216407de4c65a30eb9d49ef77e2680/9a38c/food-salad4.webp 1800w,
/img/bc216407de4c65a30eb9d49ef77e2680/cf73c/food-salad4.webp 2127w"
                sizes="(max-width: 1200px) 100vw, 1200px"
              ></source>
              <source
                srcset="/img/bc216407de4c65a30eb9d49ef77e2680/c536e/food-salad4.jpg 300w,
/img/bc216407de4c65a30eb9d49ef77e2680/91764/food-salad4.jpg 600w,
/img/bc216407de4c65a30eb9d49ef77e2680/9ace5/food-salad4.jpg 1200w,
/img/bc216407de4c65a30eb9d49ef77e2680/7d962/food-salad4.jpg 1800w,
/img/bc216407de4c65a30eb9d49ef77e2680/d381e/food-salad4.jpg 2127w"
                sizes="(max-width: 1200px) 100vw, 1200px"
              ></source>
              <img
                sizes="(max-width: 1200px) 100vw, 1200px"
                srcset="/img/bc216407de4c65a30eb9d49ef77e2680/c536e/food-salad4.jpg 300w,
/img/bc216407de4c65a30eb9d49ef77e2680/91764/food-salad4.jpg 600w,
/img/bc216407de4c65a30eb9d49ef77e2680/9ace5/food-salad4.jpg 1200w,
/img/bc216407de4c65a30eb9d49ef77e2680/7d962/food-salad4.jpg 1800w,
/img/bc216407de4c65a30eb9d49ef77e2680/d381e/food-salad4.jpg 2127w"
                src="/img/bc216407de4c65a30eb9d49ef77e2680/9ace5/food-salad4.jpg"
                alt="avocado salad"
                title=""
                loading="eager"
                fetchpriority="high"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  opacity: 1,
                  transition: 'none',
                }}
              ></img>
            </picture>

            <div className="overlay-points" />
            <header className="clearfix">
              <div
                style={{ background: 'rgba(0,0,0,0.5)', position: 'relative' }}
              >
                <h1
                  style={{
                    margin: '2em .3em 1em .3em',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  Well-Being and <span>Healthy Lifestyle</span>
                </h1>
              </div>
              <div className="container">
                <div className="row">
                  <div className="span4 offset2 header-list">
                    <h2 style={{ background: 'rgba(0,0,0,0.5)' }}>
                      <ul className="header-list">
                        <li>Healthy weight</li>
                        <li>Get moving!</li>
                        <li>Eat well</li>
                        <li>Sensible and healthy eating plans</li>
                        <li>Multifaceted nutrition</li>
                        <li>Hale and hearty life</li>
                      </ul>
                    </h2>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className="span4 text-center pagination-center"
                  >
                    <div class="constraint">
                      <button class="round-button">
                        <div class="round-button-circle">
                          <a href="#contact" class="round-button">
                            Contact Ilse
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>

        <div className="scrollblock">
          <section id="feature">
            <div className="container">
              <div className="row">
                <div className="span12">
                  <article>
                    <p
                      style={{
                        color: '#312a1e',
                        fontWeight: 'Bold',
                        textShadow:
                          ' -1px 0 #FFC300, 0 1px #FFC300, 1px 0 #FFC300, 0 -1px #FFC300',
                      }}
                    >
                      Use this page as a resource for your well-being and a
                      healthy lifestyle.
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </section>
        </div>
        <hr />
        <section id="home" className="single-page scrollblock">
          <div className="container">
            <div className="entry-content">
              <div className="align">
                {' '}
                <i className="icon-Nutritional sev_icon" />{' '}
              </div>
              <h3 className="indexh3">Welcome</h3>
              <p>
                I'm <b>Ilse Valle-Jones</b>, a a bilingual professional
                nutritionist psychotherapist living in the Suburban Philadelphia
                area of Pennsylvania. I can be your guide in designing healthy
                eating plans for your well- being and give you customized advice
                to start enjoying a healthy lifestyle.
              </p>
              <p>
                {' '}
                With over 20 years of international experience I can help you
                whether you&#39;re an individual ready to focus on their
                well-being or an industry professional that needs strategic
                advice. If you’re interested in working with me, don’t hesitate
                to <a href="#contact">contact me</a>.
              </p>

              <p>I can help you if you want to:</p>
              <p />
              <ul className="pretty-list">
                <li>Focus on a healthy weight</li>
                <li>Get moving!</li>
                <li>
                  Eat well by following a sensible and healthy eating plan
                </li>
                <li>Try sensible and healthy eating plans</li>
                <li>Try a multifaceted nutrition approach</li>
                <li>Have a hale and hearty life</li>
              </ul>
              <p />
              <p>or if you suffer from:</p>

              <p />
              <ul className="pretty-list">
                <li>obesity </li>
                <li>excess body fat </li>
                <li>constipation </li>
                <li>diabetes </li>
                <li>thyroid disease </li>
                <li>cardiovascular diseases </li>
                <li>eating disorders </li>
                <li>undernourishment </li>
                <li>other diseases </li>
              </ul>
              <p />
              <p>
                with great resources, based on your personal needs, your habits
                will soon lead to well-being and the healthier lifestyle you
                always wanted!
              </p>

              <p>
                It doesn’t matter which stage of life you are in — childhood or
                adolescence, if you’re an adult or a senior — your well-being
                and your health are important to me.
              </p>
              <hr />
            </div>
          </div>
        </section>
        <hr />

        <section id="consulting" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-cog-circled" />
            </div>
            <h3 className="indexh3">Let’s connect!</h3>

            <p className="offers">I can be your guide for:</p>
            <br />
            <div className="row">
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-clipboard sev_icon" />{' '}
                </div>
                <h2>Fitness</h2>
              </div>
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-Creating sev_icon" />{' '}
                </div>
                <h2>Nutrition</h2>
              </div>
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-Presenting sev_icon" />{' '}
                </div>
                <h2>Well-Being</h2>
              </div>
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-Setting sev_icon" />{' '}
                </div>
                <h2>Healthy mind</h2>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-Tracking sev_icon" />{' '}
                </div>
                <h2>Spanish Speaking practice</h2>
              </div>
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-Promoting sev_icon" />{' '}
                </div>
                <h2>Spanish conversation</h2>
              </div>
              <div className="span3 margin1">
                <div className="align">
                  {' '}
                  <i className="icon-writing sev_icon" />{' '}
                </div>
                <h2>EN – SP / SP – EN Translations</h2>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section id="testimonials" className="single-page hidden-phone">
          <div className="container">
            <div className="row">
              <div className="blockquote-wrapper">
                <blockquote className="mega">
                  <div className="span8">
                    <p className="alignright">
                      “To insure good health: eat lightly, breathe deeply, live
                      moderately, cultivate cheerfulness, and maintain an
                      interest in life”
                    </p>
                  </div>
                  <div className="span8">
                    <p className="cite">—William Londen</p>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section id="philosophy" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-pencil-circled" />
            </div>
            <h3 className="indexh3">Philosophy</h3>
            <div className="entry-content">
              <p>
                Your health is intimately related with your life habits, and
                your risk of suffering from disease decreases with a healthy
                lifestyle. The foods you eat, whether you exercise regularly,
                and the stress you suffer from daily all contribute to your
                health. In addition, having a healthy mind can help you deal
                with all your everyday problems.
              </p>
              <p>
                Because each individual is unique and different, your food
                intake should be personalized and appropriate for your:
              </p>

              <p />
              <ul className="pretty-list">
                <li>Age</li>
                <li>Gender</li>
                <li>Activity level</li>
                <li>Economic resources</li>
                <li>Personal habits</li>
              </ul>
              <p />
              <p>Proper Nutrition must be:</p>
              <p />
              <ul className="pretty-list">
                <li>Complete</li>
                <li>Sufficient</li>
                <li>Varied</li>
                <li>Hygienic</li>
                <li>Balanced</li>
              </ul>
              <p />
              <p>
                The better your habits are, the easier it will be to have a hale
                and hearty life!
              </p>

              <p>
                Some links that I recommend are the{' '}
                <a href="http://www.nlm.nih.gov/">
                  United States National Library of Medicine
                </a>
                , the{' '}
                <a href="http://www.nlm.nih.gov">
                  National Institutes of Health
                </a>{' '}
                and{' '}
                <a href="https://en.wikipedia.org/wiki/Main_Page">Wikipedia</a>,
                the free encyclopedia. If you ever have a question about some
                health issue, look it up in these web sites and find out the
                facts. They are very easy to use and patient friendly.
              </p>

              <p>
                Visit my{' '}
                <a href="https://www.ilsevalle.com/en/nutrition-blog/">blog</a>{' '}
                to learn more about staying fit and healthy, and keep up-to-date
                on nutrition topics.
              </p>
            </div>
            <a
              href="https://www.ilsevalle.com/en/nutrition-blog"
              className="btn btn-large"
              style={{ color: '#14112b' }}
            >
              Visit my blog
            </a>{' '}
          </div>
        </section>
        <hr />
        <section id="about" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-torso" />
            </div>
            <h3 className="indexh3">About Ilse</h3>

            <div className="span8 offset2">
                <br/>
              <div itemScope="" itemType="http://schema.org/Person">
                <div className="teamalign">
                  <Img
                    sizes={
                      this.props.data.ilse.edges[0].node.childImageSharp.fluid
                    }
                    alt="Ilse Valle-Jones"
                  />
                </div>
                <br/>
                <span id="spanh3">
                  <span
                    itemProp="name"
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                      display: 'table',
                    }}
                  >
                    Ilse Valle-Jones
                  </span>
                </span>
                <div className="job-position">
                  <span itemProp="jobTitle">nutritionist</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="span10 offset1">
                <div className="featurette">
                  <h2 className="featurette-heading">
                    <span className="muted" style={{ fontFamily: 'Open Sans' }}>
                      a little bit about me
                    </span>
                  </h2>
                  <p>
                    <a href="#resume" style={{ color: '#8e82ff' }}>
                      Résumé
                    </a>
                  </p>

                  <p>
                    I am a bilingual nutritionist psychotherapist. I majored in
                    Nutrition at the School of Dietetics and Nutrition. I also
                    have a Master’s degree in Psychotherapy from Eleia Center.
                    In addition, I have completed courses in Eating Disorders
                    (Anahuac University) and Enology (Anahuac University). I
                    also have a minor in Food Chemistry (UNAM).
                  </p>
                </div>

                <p> </p>
                <ul className="prizes">
                  <li>
                    First place prize for basic research in nutrition from the
                    Nutrition Society A.C. (SNAC), with the entry: “Effects Of
                    The Administration Of Antioxidant Vitamins In The Hepatic
                    Reversion Of The Inhibition For Hepatic Regeneration Caused
                    By The Consumption Of Alcohol” conducted at the National
                    Institute of Medical Sciences and Nutrition Salvador
                    Zubiran.
                  </li>

                  <li>
                    Second place prize in basic research at the 17th National
                    AMMFEN Congress, with the entry: “Effects Of The
                    Administration Of Antioxidant Vitamins In The Hepatic
                    Reversion Of The Inhibition For Hepatic Regeneration Caused
                    By The Consumption Of Alcohol” in Yucatan, Mexico.{' '}
                  </li>

                  <li>
                    Acknowledgement as expositor in free compositions, with the
                    entry: “Effects Of The Administration Of Antioxidant
                    Vitamins In The Hepatic Reversion Of The Inhibition For
                    Hepatic Regeneration Caused By The Consumption Of Alcohol”
                    at the Medical Symposium from the National Medical Center
                    ‘20 de Noviembre’ and an the Annual National Biochemistry
                    Congress.
                  </li>

                  <li>
                    Acknowledgement as expositor in the 1st Week of Science,
                    Technology and Art at the Dietetics and Nutrition School,
                    with the entry: “Apple Fermentation and Yeast Isolation to
                    Elaborate Cider” (together with Maricela Martinez).
                  </li>

                  <li>
                    Merit economic award. Bestfoods Educational Foundation
                  </li>

                  <li>Participation in sports events</li>

                  <li>
                    Acknowledgement for dedication and attitude in daily work.
                    Angloamericano
                  </li>
                </ul>
                <p />
                <div className="featurette">
                  {' '}
                  <p>
                    I've had several articles published by the newspapers
                    ‘‘Nosotros,’’ belonging to the{' '}
                    <a href="https://www.gob.mx/issste">ISSSTE</a>, and ‘‘
                    <a href="http://www.cronica.com.mx/">Today’s Chronicle</a>
                    ’’. (All in Spanish){' '}
                  </p>
                </div>
                <p />
                <ul className="prizes">
                  <li>
                    <a href="//www.ilsevalle.com/blog/2005/10/ventajas-de-la-dieta-mediterranea-3/">
                      Advantages of the Mediterranean Diet
                    </a>
                  </li>
                  <li>
                    <a href="//www.ilsevalle.com/blog/2005/10/soya-ventajas-de-uso-y-preparacion-3/">
                      Soy, use and preparation advantages
                    </a>
                  </li>
                  <li>
                    <a href="//www.ilsevalle.com/blog/2005/10/migrana-su-relacion-con-la-alimentacion-3/">
                      Migraine and Nourishment
                    </a>
                  </li>
                  <li>
                    <a href="//www.ilsevalle.com/blog/">See all articles</a>
                  </li>
                </ul>
                <p />
                <div className="featurette">
                  <p>
                    <a name="resume" style={{ color: '#8e82ff' }}>
                      Résumé
                    </a>
                  </p>
                </div>
                <p />
                <ul className="prizes">
                  <li>
                    <a href="docs/2023.10.12-ilsevalle-resume.html">
                      View on the Web
                    </a>
                  </li>
                  <li>
                    <a href="docs/2023.10.12-ilsevalle-resume.doc">Word file</a>
                  </li>
                  <li>
                    <a href="docs/2023.10.12-ilsevalle-resume.pdf">PDF file</a>
                  </li>
                </ul>
                <p />

                <hr className="featurette-divider" />
              </div>
            </div>
          </div>
        </section>

        <section id="contact" className="single-page scrollblock">
          <div className="container">
            <div className="align">
              <i className="icon-mail2" />
            </div>
            <h3 className="indexh3">Contact</h3>
            <h2>
              <p>
                Whether you want to chat, send me a comment or just send fan
                mail, you can email me at:
              </p>
              <p>
                <Mailto email="ilse@ilsevalle.com"  obfuscatedHref=""/>
              </p>
              <p>
                {' '}
                or if you prefer, you can use the form below to contact me:
              </p>
            </h2>
            <div className="row">
              <div className="span12">
                <div className="cform" id="theme-form">
                  <div id="note" />
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Template>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    imageHead: allFile(
      filter: { relativePath: { regex: "/food-salad4.jpg/" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 45) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    ilse: allFile(filter: { relativePath: { regex: "/ilse-valle-jones.jpg/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
